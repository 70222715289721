import {
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  Badge,
  BadgeAlert,
  BadgeCheck,
  BarChartBig,
  Blocks,
  BookOpenCheck,
  Bot,
  BriefcaseBusiness,
  Bug,
  Calendar,
  Check,
  CheckCircle,
  CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clipboard,
  Command,
  Copy,
  CreditCard,
  Crosshair,
  Download,
  Eye,
  File,
  FileIcon,
  FileText,
  Film,
  FolderIcon,
  FolderOpenIcon,
  Github,
  Globe,
  HardDriveUpload,
  Heart,
  HelpCircle,
  Image,
  Inbox,
  Info,
  Instagram,
  KeySquare,
  Laptop,
  LifeBuoy,
  LineChart,
  Link,
  Link2,
  Linkedin,
  ListFilter,
  Loader2,
  LucidePaperclip,
  LucideProps,
  Mail,
  MessageCircleHeart,
  Moon,
  MoreVertical,
  Package,
  Paperclip,
  Pencil,
  Pizza,
  Play,
  Plug,
  Plus,
  Save,
  ScrollText,
  Settings,
  ShoppingCart,
  Sparkles,
  SunMedium,
  Target,
  TargetIcon,
  Trash,
  Twitter,
  Undo,
  Upload,
  UploadCloud,
  User,
  Users,
  Video,
  WandSparkles,
  X,
  XCircle,
} from "lucide-react";

export const Icons = {
  add: Plus,
  analytics: BarChartBig,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  Badge,
  badge: Badge,
  BadgeAlert,
  badgeAlert: BadgeAlert,
  BadgeCheck,
  badgeCheck: BadgeCheck,
  billing: CreditCard,
  blocks: Blocks,
  bookOpenCheck: BookOpenCheck,
  bot: Bot,
  briefcase: BriefcaseBusiness,
  bug: Bug,
  calendar: Calendar,
  chartLine: LineChart,
  check: Check,
  checkCircle: CheckCircle,
  checkCircle2: CheckCircle2,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  circleHelp: HelpCircle,
  clipboard: Clipboard,
  close: X,
  copy: Copy,
  crosshair: Crosshair,
  download: Download,
  ellipsis: MoreVertical,
  fileIcon: FileIcon,
  film: Film,
  filter: ListFilter,
  folderIcon: FolderIcon,
  folderOpenIcon: FolderOpenIcon,
  gitHub: Github,
  globe: Globe,
  hardDriveUpload: HardDriveUpload,
  heart: Heart,
  help: HelpCircle,
  inbox: Inbox,
  info: Info,
  instagram: Instagram,
  keySquare: KeySquare,
  laptop: Laptop,
  lifeBuoy: LifeBuoy,
  link: Link,
  link2: Link2,
  linkedIn: Linkedin,
  logo: Command,
  mail: Mail,
  media: Image,
  messageCircleHeart: MessageCircleHeart,
  moon: Moon,
  package: Package,
  page: File,
  paperclip: Paperclip,
  pencil: Pencil,
  pizza: Pizza,
  play: Play,
  plug: Plug,
  post: FileText,
  preview: Eye,
  save: Save,
  scrollText: ScrollText,
  settings: Settings,
  shoppingCart: ShoppingCart,
  sparkles: Sparkles,
  spinner: Loader2,
  sun: SunMedium,
  target: Target,
  trash: Trash,
  twitter: Twitter,
  undo: Undo,
  upload: Upload,
  uploadCloud: UploadCloud,
  user: User,
  users: Users,
  video: Video,
  wandSparkles: WandSparkles,
  warning: AlertTriangle,
  xCircle: XCircle,
};
